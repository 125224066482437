const FathomEvents = {
  'auto-play-next': '8BXCJ7VJ',
  'click-download': 'K4H1KTD7',
  'click-play': 'X36DULXV',
  'click-share': 'INMXSXHU',
  'click-lets-go': 'YJ2XBOUM',
  'episode-view-click-lets-go': 'QSOMYEWV',
  'episode-view-click-download': '8WXEU5W3',
  'episode-view-click-share': '5QRADOSZ',
  'shared-twitter': 'BDKYUSXZ',
  'shared-facebook': 'MJHKMBOW',
  'shared-linkedin': 'L2HFVKEJ',
  'shared-pinterest': 'AIFSGMB8',
  'shared-reddit': 'UVJYGTNY',
  'shared-whatsapp': 'E2UJPNQE',
  'shared-telegram': 'NCUAUYDA',
  'shared-email': 'A7Q2PLO5'
}

const trackFathomGoal = (eventName) => {
  const id = FathomEvents[eventName]
  if (id) {
    try {
      global.fathom.trackGoal(id, 0)
      console.log('tracked event:', eventName)
    } catch (err) {
      console.error('failed to track event:', eventName)
    }
  } else {
    console.error('unknown event:', eventName)
  }
}

export {
  trackFathomGoal
}
