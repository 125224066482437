import { formatDuration } from '../libs/formatting'
import moment from 'moment'

let episodes

const fetchEpisodes = async () => {
  if (episodes) {
    return episodes
  }

  const resp = await fetch('https://2kb8krrg6k.execute-api.us-east-1.amazonaws.com/episodes')

  const today = new Date().toJSON()
  console.log(today)
  episodes = await resp.json()
  episodes.forEach(x => {
    const prefix = `#${x.episode_number}: `
    x.title = x.title.substring(prefix.length)

    const date = moment(x.published_at.substring(0, 10), 'YYYY-MM-DD')
    x.date = date.format('ll') // e.g. Aug 1, 2022
    x.length = formatDuration(x.duration)
  })
  episodes = episodes.filter(x => x.published_at <= today)

  return episodes
}

export {
  fetchEpisodes
}
