const formatDuration = (duration) => {
  const secs = duration % 60
  const secsStr = secs.toString().padStart(2, '0')
  const mins = Math.floor(duration / 60) % 60
  const minsStr = mins.toString().padStart(2, '0')
  const hours = Math.floor(duration / 60 / 60)
  return `${hours ? `${hours}:` : ''}${minsStr}:${secsStr}`
}

export {
  formatDuration
}
