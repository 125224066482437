<template>
  <div class="mt-5">
    <ul class="grid grid-cols-7 gap-4 lg:flex place-items-center items-center lg:max-w-4xl">
      <li class="h-14 w-14 flex justify-center items-center"
          v-for="tile of tiles" v-bind:key="tile.name">
        <a :href="tile.url" target="_blank">
          <img class="h-10 w-10" :alt="tile.name" :title="tile.name" :src="tile.img">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tiles: [
        {
          name: 'YouTube',
          img: '/images/logo-youtube.svg',
          url: 'https://www.youtube.com/watch?v=7Uh9OKOBipM&list=PLEmxvZeTV09321DS-uzXW2OXBiyYPnamL'
        },
        {
          name: 'Apple Podcasts',
          img: '/images/logo-apple-podcasts.svg',
          url: 'https://podcasts.apple.com/gb/podcast/real-world-serverless-with-theburningmonk/id1499753495'
        },
        {
          name: 'Spotify',
          img: '/images/logo-spotify.svg',
          url: 'https://open.spotify.com/show/4Ts4BGGcQvwtvwF0XXq0nE'
        },
        {
          name: 'Google Podcasts',
          img: '/images/logo-google-podcasts.svg',
          url: 'https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS84Nzc3NDcucnNz'
        },
        {
          name: 'Amazon Music',
          img: '/images/logo-amazon-music.svg',
          url: 'https://music.amazon.co.uk/podcasts/773b78aa-9ae6-4122-af76-c1cc11042672/real-world-serverless-with-theburningmonk'
        },
        {
          name: 'Stitcher',
          img: '/images/logo-stitcher.svg',
          url: 'https://www.stitcher.com/podcast/real-world-serverless-with-theburningmonk'
        },
        {
          name: 'iHeart Radio',
          img: '/images/logo-iheart-radio.svg',
          url: 'https://www.iheart.com/podcast/269-real-world-serverless-with-59428224'
        },
        {
          name: 'Podcast Addict',
          img: '/images/logo-podcast-addict.svg',
          url: 'https://podcastaddict.com/podcast/2543709'
        },
        {
          name: 'Podchaser',
          img: '/images/logo-podchaser.svg',
          url: 'https://www.podchaser.com/f/pod/1499753495'
        },
        {
          name: 'Pocket Casts',
          img: '/images/logo-pocketcasts.svg',
          url: 'http://pca.st/itunes/1499753495'
        },
        // {
        //   name: 'Deezer',
        //   img: '/images/logo-deezer.svg',
        //   url: 'https://www.deezer.com/us/show/1524772'
        // },
        {
          name: 'Listen Notes',
          img: '/images/logo-listennotes.svg',
          url: 'https://www.listennotes.com/podcasts/real-world-serverless-with-theburningmonk-NYpy3XW4BUm'
        },
        {
          name: 'Player FM',
          img: '/images/logo-playerfm.svg',
          url: 'https://player.fm/series/real-world-serverless-with-theburningmonk'
        },
        {
          name: 'Overcast',
          img: '/images/logo-overcast.svg',
          url: 'https://overcast.fm/itunes1499753495'
        },
        {
          name: 'Castro',
          img: '/images/logo-castro.svg',
          url: 'https://castro.fm/itunes/1499753495'
        },
        {
          name: 'Castbox',
          img: '/images/logo-castbox.svg',
          url: 'https://castbox.fm/vic/1499753495'
        }
      ]
    }
  }
}
</script>

<style scoped>
img {
  transition: transform 0.2s ease-out;
}

img:hover {
  transform: scale(1.2);
}
</style>
