<template>
  <div class="p-10 my-5 mx-auto rounded-md shadow-md lg:w-3/4 bg-mybg
              flex flex-col items-center lg:flex-row">
    <img class="h-44 w-44 rounded-md lg:mr-5"
      loading="lazy"
      :srcset="`https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,h_176/website/logo-ep${episode.episode_number}.jpg 320w`"
      sizes="176px"
      :src="episode.artwork_url">

    <div class="flex flex-col w-full">
      <!-- metadata -->
      <div class="flex flex-col md:flex-row text-sm text-gray-600">
        <div class="flex items-center md:mr-3">
          <div class="w-6">
            <svg class="h-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM80 256C71.16 256 64 263.2 64 272V368C64 376.8 71.16 384 80 384H176C184.8 384 192 376.8 192 368V272C192 263.2 184.8 256 176 256H80z"/>
            </svg>
          </div>
          <span>{{episode.date}}</span>
        </div>

        <div class="flex items-center md:mr-3">
          <div class="w-6">
            <svg class="h-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM512 64C529.7 64 544 78.33 544 96C544 113.7 529.7 128 512 128H256C238.3 128 224 113.7 224 96C224 78.33 238.3 64 256 64H512zM512 224C529.7 224 544 238.3 544 256C544 273.7 529.7 288 512 288H256C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224H512zM512 384C529.7 384 544 398.3 544 416C544 433.7 529.7 448 512 448H256C238.3 448 224 433.7 224 416C224 398.3 238.3 384 256 384H512z"/>
            </svg>
          </div>
          <span>episode {{episode.episode_number}}</span>
        </div>

        <div class="flex items-center md:mr-3">
          <div class="w-6">
            <svg class="h-4 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M272 0C289.7 0 304 14.33 304 32C304 49.67 289.7 64 272 64H256V98.45C293.5 104.2 327.7 120 355.7 143L377.4 121.4C389.9 108.9 410.1 108.9 422.6 121.4C435.1 133.9 435.1 154.1 422.6 166.6L398.5 190.8C419.7 223.3 432 262.2 432 304C432 418.9 338.9 512 224 512C109.1 512 16 418.9 16 304C16 200 92.32 113.8 192 98.45V64H176C158.3 64 144 49.67 144 32C144 14.33 158.3 0 176 0L272 0zM248 192C248 178.7 237.3 168 224 168C210.7 168 200 178.7 200 192V320C200 333.3 210.7 344 224 344C237.3 344 248 333.3 248 320V192z"/>
            </svg>
          </div>
          <span>{{episode.length}}</span>
        </div>
      </div>

      <div class="mt-5">
        <p class="text-lg tracking-tight lg:tracking-normal font-bold">{{episode.title}}</p>
      </div>

      <div class="mt-2 flex gap-x-5">
        <a href="#" class="button w-fit py-2 px-4 text-center font-medium"
           @click="$emit('play', episode.episode_number)">
          Play episode
        </a>

        <a :href="`/episode/${episode.episode_number}`" target="_blank"
           class="button w-fit py-2 px-4 text-center font-medium">
          Show notes
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpisodeComponent',
  props: ['episode'],
  emits: ['play']
}
</script>
