<template>
  <div class="z-50 max-w-3xl h-fit bg-white rounded-lg shadow-lg flex flex-col">
    <div class="w-full flex justify-end">
      <a href="#" @click="$emit('close')"
         class="md:m-2 text-3xl font-extrabold hover:bg-gray-300 py-1 px-3 rounded-full">
        X
      </a>
    </div>

    <div class="flex flex-col items-center text-center">
      <img class="h-48 m-5"
        loading="lazy"
        src="https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,h_384/website/logo-prsls.png">
      <div class="mx-5 mb-5">
        <h1 class="text-2xl tracking-tight font-bold">Production-Ready Serverless</h1>
        <p class="text-md">
          Learn <span class="font-bold">BEST PRACTICES</span> for building
          production-ready serverless applications.
        </p>
        <p class="text-base text-gray-600">
          Topics covered: serverless use cases, testing, CI/CD, security
          best practices, event-driven architectures, observability, and
          much more!
        </p>
      </div>
    </div>
    <a href="https://productionreadyserverless.com/?utm_source=rwsls&utm_medium=popup"
       target="_blank"
       class="button m-4 mt-0 p-3 text-lg text-center flex flex-row justify-center bg-myred font-extrabold"
       @click="$emit('click-lets-go')">
      <svg class="h-7 mx-2 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M156.6 384.9L125.7 353.1C117.2 345.5 114.2 333.1 117.1 321.8C120.1 312.9 124.1 301.3 129.8 288H24C15.38 288 7.414 283.4 3.146 275.9C-1.123 268.4-1.042 259.2 3.357 251.8L55.83 163.3C68.79 141.4 92.33 127.1 117.8 127.1H200C202.4 124 204.8 120.3 207.2 116.7C289.1-4.07 411.1-8.142 483.9 5.275C495.6 7.414 504.6 16.43 506.7 28.06C520.1 100.9 516.1 222.9 395.3 304.8C391.8 307.2 387.1 309.6 384 311.1V394.2C384 419.7 370.6 443.2 348.7 456.2L260.2 508.6C252.8 513 243.6 513.1 236.1 508.9C228.6 504.6 224 496.6 224 488V380.8C209.9 385.6 197.6 389.7 188.3 392.7C177.1 396.3 164.9 393.2 156.6 384.9V384.9zM384 167.1C406.1 167.1 424 150.1 424 127.1C424 105.9 406.1 87.1 384 87.1C361.9 87.1 344 105.9 344 127.1C344 150.1 361.9 167.1 384 167.1z"/>
      </svg>
      Let's GO!
    </a>
  </div>
</template>

<script>
export default {
  emits: ['click-lets-go']
}
</script>
