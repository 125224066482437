<template>
  <div v-if="episode" class="relative w-full border-2 pb-2 border-myred flex flex-col items-center">
    <div class="flex flex-col items-center w-full">
      <div class="flex items-center">
        <img class="h-28 w-28 mr-5"
          loading="lazy"
          :srcset="`https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,h_176/website/logo-ep${episode.episode_number}.jpg 176w`"
          sizes="176px"
          :src="episode.artwork_url">

        <p class="text-lg tracking-tight lg:tracking-normal font-bold">{{ episode.title}}</p>
      </div>
    </div>

    <AudioPlayer ref="player"
      :url="episode.audio_url"
      @share="$emit('share', episode.episode_number)"
      @finished="$emit('finished', episode.episode_number)"
      @download="$emit('download', episode.episode_number)" />
  </div>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue'

export default {
  props: ['episode'],
  emits: ['share', 'download', 'finished'],
  components: {
    AudioPlayer
  },
  methods: {
    jumpToTimestamp (seconds) {
      this.$refs.player.jumpToTimestamp(seconds)
    },

    start () {
      this.$refs.player.play()
    }
  }
}
</script>
