<template>
  <div v-if="segments.length > 0" class="mt-10 text-left w-full">
    <h1 class="text-xl font-bold">Transcript</h1>
    <ul class=" border-t border-gray-600 pt-5">
      <li v-for="segment of segments" v-bind:key="segment.id" class="mt-5 mb-10">
        <div class="flex items-center">
          <h3 :class="`text-lg, font-bold ${segment.styles}`">
            {{ segment.heading }}
          </h3>
          <a href="#" @click="$emit('goto', segment.startTime)">
            <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z"/>
            </svg>
          </a>
        </div>

        <p v-for="sentence of segment.sentences" v-bind:key="sentence"
          :class="`py-1 ${segment.styles}`">
          {{sentence}}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { drop } from 'lodash'

export default {
  name: 'TranscriptComponent',
  props: ['episodeNumber'],
  emits: ['goto'],
  data () {
    return {
      segments: []
    }
  },
  async mounted () {
    const baseUrl = window.location.origin
    const resp = await fetch(`${baseUrl}/transcriptions/ep${this.episodeNumber}.txt`)
    if (resp.status !== 200) {
      console.log('Transcript not found')
    } else {
      const text = await resp.text()
      const lines = text.split('\n').filter(x => x)

      let currentSegment
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        // e.g. Yan Cui: 00:12
        if (line.match(/^[\w ]+: \d+:\d+/)) {
          if (currentSegment) {
            this.segments.push(currentSegment)
          }

          const isHost = line.startsWith('Yan Cui')
          const styles = isHost
            ? 'text-left pr-2'
            : 'text-left pr-2'
          const [sec, min, hour] = drop(line.split(':'), 1)
            .map(x => x.trim())
            .reverse()
          let startTime = parseInt(sec)
          startTime += 60 * parseInt(min)
          if (hour) {
            startTime += 3600 * parseInt(hour)
          }

          currentSegment = {
            id: this.segments.length + 1,
            heading: line.trimEnd(),
            isHost,
            styles,
            startTime,
            sentences: []
          }
        } else {
          const sentences = line.match(/[^.!?]+[.!?]+/g)
          if (sentences) {
            sentences.forEach(x => currentSegment.sentences.push(x))
          }
        }
      }
    }
  }
}
</script>
