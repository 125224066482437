<template>
  <div class="py-5 lg:py-18 mt-5 w-full bg-white">
    <ul>
      <li v-for="episode of episodes" v-bind:key="episode.title">
        <Episode :episode="episode" @play="onPlay" />
      </li>
    </ul>

    <div v-if="hasMore" class="w-full flex justify-center">
      <a href="#" @click="$emit('load-more')"
         class="button px-8 py-3 text-md font-bold text-center">
        Load more
      </a>
    </div>
  </div>
</template>

<script>
import Episode from './Episode.vue'

export default {
  props: ['episodes', 'hasMore'],
  emits: ['load-more', 'play'],
  components: {
    Episode
  },
  methods: {
    onPlay (episodeNumber) {
      this.$emit('play', episodeNumber)
    }
  }
}
</script>

<style scoped>
.negPadding {
  padding: -10px;
}
</style>
