<template>
  <div class="w-full">
    <audio v-if="url" preload="none" ref="player" class="w-full"
        @timeupdate="onTimeUpdate"
        @play="this.playing = true"
        @pause="this.playing = false"
        @ended="onEnded"
        @loadedmetadata="onLoadedMetadata">
      <source ref="source" :src="url" type="audio/mpeg">
      Your browser does not support the audio tag.
    </audio>
    <div class="md:hidden">
      <div class="flex justify-center">
        <!-- controls -->
        <div class="flex items-center">
          <a class="mx-2 flex" href="#" @click="rewind()">
            <span class="text-sm mr-1 text-myred font-bold">10s</span>
            <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M459.5 71.41l-171.5 142.9v83.45l171.5 142.9C480.1 457.7 512 443.3 512 415.1V96.03C512 68.66 480.1 54.28 459.5 71.41zM203.5 71.41L11.44 231.4c-15.25 12.87-15.25 36.37 0 49.24l192 159.1c20.63 17.12 52.51 2.749 52.51-24.62v-319.9C255.1 68.66 224.1 54.28 203.5 71.41z"/>
            </svg>
          </a>
          <a v-if="!playing" class="mx-2" href="#" @click="play()">
            <svg class="h-10 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z"/>
            </svg>
          </a>
          <a v-if="playing" class="mx-2" href="#" @click="pause()">
            <svg class="h-10 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM224 191.1v128C224 337.7 209.7 352 192 352S160 337.7 160 320V191.1C160 174.3 174.3 160 191.1 160S224 174.3 224 191.1zM352 191.1v128C352 337.7 337.7 352 320 352S288 337.7 288 320V191.1C288 174.3 302.3 160 319.1 160S352 174.3 352 191.1z"/>
            </svg>
          </a>
          <a class="mx-2 flex" href="#" @click="forward()">
            <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M52.51 440.6l171.5-142.9V214.3L52.51 71.41C31.88 54.28 0 68.66 0 96.03v319.9C0 443.3 31.88 457.7 52.51 440.6zM308.5 440.6l192-159.1c15.25-12.87 15.25-36.37 0-49.24l-192-159.1c-20.63-17.12-52.51-2.749-52.51 24.62v319.9C256 443.3 287.9 457.7 308.5 440.6z"/>
            </svg>
            <span class="text-sm ml-1 text-myred font-bold">10s</span>
          </a>
        </div>
        <!-- utilities -->
        <div class="flex items-center">
          <!-- playback speed -->
          <a href="#" class="mx-2 border-2 border-myred text-xs text-center align-middle tracking-tighter font-bold py-1 w-12"
            @click="changePlaybackSpeed">
            {{ playbackSpeed }}x
          </a>

          <!-- download -->
          <a :href="url" class="mx-2 w-5 text-center" target="_blank" @click="$emit('download')">
            <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/>
            </svg>
          </a>

          <!-- share -->
          <a href="#" class="mx-2 w-5 text-center" @click="$emit('share')">
            <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128C448 181 405 224 352 224C326.1 224 302.6 213.8 285.4 197.1L285.4 197.1z"/>
            </svg>
          </a>
        </div>
      </div>
      <!-- timeline -->
      <div class="my-4 px-3 w-full flex justify-between items-center">
        <span class="text-sm">{{ currentTimeDisplay }}</span>

        <input ref="slider" type="range"
          :value="currentTime" min="0" :max="duration"
          class="w-full mx-2 h-2 bg-gray-400 bg-opacity-50 rounded-lg z-10 cursor-pointer"
          @input="onInput">

        <span class="text-sm text-left w-10">{{ durationDisplay }}</span>
      </div>
    </div>

    <div class="hidden md:flex flex-col md:flex-row items-center">
      <!-- controls -->
      <div class="flex items-center">
        <a class="mx-2 flex" href="#" @click="rewind()">
          <span class="text-sm mr-1 text-myred font-bold">10s</span>
          <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M459.5 71.41l-171.5 142.9v83.45l171.5 142.9C480.1 457.7 512 443.3 512 415.1V96.03C512 68.66 480.1 54.28 459.5 71.41zM203.5 71.41L11.44 231.4c-15.25 12.87-15.25 36.37 0 49.24l192 159.1c20.63 17.12 52.51 2.749 52.51-24.62v-319.9C255.1 68.66 224.1 54.28 203.5 71.41z"/>
          </svg>
        </a>
        <a v-if="!playing" class="mx-2" href="#" @click="play()">
          <svg class="h-10 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z"/>
          </svg>
        </a>
        <a v-if="playing" class="mx-2" href="#" @click="pause()">
          <svg class="h-10 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM224 191.1v128C224 337.7 209.7 352 192 352S160 337.7 160 320V191.1C160 174.3 174.3 160 191.1 160S224 174.3 224 191.1zM352 191.1v128C352 337.7 337.7 352 320 352S288 337.7 288 320V191.1C288 174.3 302.3 160 319.1 160S352 174.3 352 191.1z"/>
          </svg>
        </a>
        <a class="mx-2 flex" href="#" @click="forward()">
          <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M52.51 440.6l171.5-142.9V214.3L52.51 71.41C31.88 54.28 0 68.66 0 96.03v319.9C0 443.3 31.88 457.7 52.51 440.6zM308.5 440.6l192-159.1c15.25-12.87 15.25-36.37 0-49.24l-192-159.1c-20.63-17.12-52.51-2.749-52.51 24.62v319.9C256 443.3 287.9 457.7 308.5 440.6z"/>
          </svg>
          <span class="text-sm ml-1 text-myred font-bold">10s</span>
        </a>
      </div>
      <!-- timeline -->
      <div class="my-4 mx-3 w-full flex justify-between items-center">
        <span class="text-sm">{{ currentTimeDisplay }}</span>

        <input ref="slider" type="range"
          :value="currentTime" min="0" :max="duration"
          class="w-full mx-2 h-2 bg-gray-400 bg-opacity-50 rounded-lg z-10 cursor-pointer"
          @input="onInput">

        <span class="text-sm text-left w-10">{{ durationDisplay }}</span>
      </div>
      <!-- utilities -->
      <div class="flex items-center">
        <!-- playback speed -->
        <a href="#" class="mx-2 border-2 border-myred text-xs text-center align-middle tracking-tighter font-bold py-1 w-12"
           @click="changePlaybackSpeed">
          {{ playbackSpeed }}x
        </a>

        <!-- download -->
        <a :href="url" class="mx-2 w-5 text-center" target="_blank" @click="$emit('download')">
          <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/>
          </svg>
        </a>

        <!-- share -->
        <a href="#" class="mx-2 w-5 text-center" @click="$emit('share')">
          <svg class="h-5 fill-myred" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128C448 181 405 224 352 224C326.1 224 302.6 213.8 285.4 197.1L285.4 197.1z"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDuration } from '../libs/formatting'

const playbackSpeeds = [0.5, 0.75, 1, 1.25, 1.5, 2]

export default {
  props: ['url'],
  emits: ['share', 'download', 'finished'],
  data () {
    return {
      playing: false,
      currentTime: 0,
      currentTimeDisplay: formatDuration(0),
      duration: 0,
      durationDisplay: '--:--',
      percentagePlayed: 0,
      playbackSpeed: 1,
      volume: 1, // 0-1
      showVolume: false
    }
  },
  watch: {
    url (newUrl, oldUrl) {
      this.$refs.source.src = this.url
      this.$refs.player.load()
      this.$refs.player.play()
    }
  },
  methods: {
    play () {
      this.$refs.player.play()
      this.playing = true
    },

    pause () {
      this.$refs.player.pause()

      this.playing = false
    },

    rewind () {
      const newTime = this.$refs.player.currentTime - 10
      this.$refs.player.currentTime = Math.max(0, newTime)
    },

    forward () {
      const duration = this.$refs.player.duration
      const newTime = this.$refs.player.currentTime + 10
      this.$refs.player.currentTime = Math.min(duration, newTime)
    },

    onEnded () {
      this.$emit('finished')
      this.playing = false
    },

    onTimeUpdate () {
      this.currentTime = this.$refs.player.currentTime
      const seconds = Math.floor(this.currentTime)
      this.currentTimeDisplay = formatDuration(seconds)
    },

    onLoadedMetadata () {
      this.duration = this.$refs.player.duration
      const seconds = Math.floor(this.duration)
      this.durationDisplay = formatDuration(seconds)
    },

    onInput () {
      this.$refs.player.currentTime = this.$refs.slider.value
    },

    changePlaybackSpeed () {
      const idx = playbackSpeeds.indexOf(this.playbackSpeed)
      const newIdx = (idx + 1) % playbackSpeeds.length
      this.playbackSpeed = playbackSpeeds[newIdx]
      this.$refs.player.playbackRate = this.playbackSpeed
    },

    jumpToTimestamp (seconds) {
      this.$refs.player.currentTime = seconds
    },

    toggleVolume () {
      if (this.volume > 0) {
        this.volume = 0
      } else {
        this.volume = 1
      }

      this.$refs.player.volume = this.volume
    }
  }
}
</script>
