<template>
  <div class="relative h-screen flex flex-col justify-between">
    <!-- Overlay -->
    <div v-if="showShareOverlay || showPrslsOverlay" class="absolute z-40 top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex pt-16 justify-center">
      <ShareOverlay
        v-if="showShareOverlay"
        :episode="sharingEpisode"
        @close="showShareOverlay = false"
        @shared="onShared" />

      <PrslsOverlay
        v-if="showPrslsOverlay"
        @close="showPrslsOverlay = false"
        @click-lets-go="trackEvent('click-lets-go')" />
    </div>

    <!-- Prsls ad button -->
    <div class="hidden md:flex absolute top-0 left-0 w-screen justify-end">
      <a href="#" class="mr-4 -m-2 bg-myred pt-4 pb-2 px-4 rounded-lg text-white font-bold shadow-sm"
         @click="showPrslsOverlay = true">
        Level up your serverless skills
      </a>
    </div>

    <!-- Main body -->
    <div class="overflow-scroll">
      <HeroSection />

      <AllEpisodes
        :episodes="shownEpisodes"
        :hasMore="hasMore"
        @load-more="loadMore()"
        @play="onPlay" />
    </div>

    <PlayingEpisode
      ref="player"
      :episode="playingEpisode"
      @share="shareEpisode"
      @download="trackEvent('click-download')"
      @finished="finishedEpisode" />
  </div>
</template>

<script>
import HeroSection from '../components/HeroSection.vue'
import AllEpisodes from '../components/AllEpisodes.vue'
import PlayingEpisode from '../components/PlayingEpisode.vue'
import ShareOverlay from '../components/ShareOverlay.vue'
import PrslsOverlay from '../components/PrslsOverlay.vue'
import { fetchEpisodes } from '../libs/buzzsprout'
import { trackFathomGoal } from '../libs/fathom'
import { take } from 'lodash'

export default {
  components: {
    HeroSection,
    AllEpisodes,
    PlayingEpisode,
    ShareOverlay,
    PrslsOverlay
  },
  data () {
    return {
      episodes: [],
      shownEpisodes: [],
      hasMore: false,
      playingEpisode: undefined,
      showShareOverlay: false,
      showPrslsOverlay: false,
      sharingEpisode: undefined
    }
  },
  async mounted () {
    const episodes = await fetchEpisodes()
    console.log(episodes)

    this.episodes = episodes
    this.shownEpisodes = take(episodes, 10)
    this.hasMore = this.episodes.length > this.shownEpisodes.length
    this.playingEpisode = this.episodes[0]
  },
  methods: {
    loadMore () {
      this.shownEpisodes = take(this.episodes, this.shownEpisodes.length + 10)
      this.hasMore = this.episodes.length > this.shownEpisodes.length
    },

    onPlay (episodeNumber) {
      if (this.playingEpisode.episode_number === episodeNumber) {
        this.$refs.player.start()
      } else {
        const episode = this.episodes.find(x => x.episode_number === episodeNumber)
        this.playingEpisode = episode
      }

      this.trackEvent('click-play')
    },

    shareEpisode (episodeNumber) {
      const episode = this.episodes.find(x => x.episode_number === episodeNumber)
      this.sharingEpisode = episode
      this.showShareOverlay = true
      this.trackEvent('click-share')
    },

    finishedEpisode (episodeNumber) {
      const nextEpisodeNumber = episodeNumber - 1
      const nextEpisode = this.episodes.find(x => x.episode_number === nextEpisodeNumber)
      if (nextEpisode) {
        this.playingEpisode = nextEpisode
        this.trackEvent('auto-play-next')
      }
    },

    onShared (platform) {
      this.trackEvent(`shared-${platform}`)
    },

    trackEvent (eventName) {
      trackFathomGoal(eventName)
    }
  }
}
</script>
