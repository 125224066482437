<template>
  <div class="mt-5 md:mt-10 mx-auto max-w-7xl px-4
              grid grid-cols-1 grid-y-10 justify-items-center
              xl:flex xl:justify-center">
    <img class="h-44 w-44 xl:h-72 xl:w-72 mx-4"
      srcset="
        https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,w_176/website/logo.png 176w,
        https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,w_288/website/logo.png 288w"
      sizes="(max-width: 1280px) 176px, 288px"
      src="/images/logo.png">

    <div>
      <h1 class="text-2xl text-center tracking-tight font-extrabold xl:pt-5 xl:text-left">
        Real-World Serverless Podcast
      </h1>
      <p class="mt-4 xl:max-w-4xl">
        Want to know how companies are using serverless technologies in the real-world?
        Does it work for enterprises or is it only good for start-ups?
        Or are you curious about the best practices that teams use to make serverless
        work at large scale? Then you've come to the right place.
      </p>
      <p class="mt-2 xl:max-w-4xl">
        Your host is <span class="font-bold">Yan Cui</span>, aka
        <a target="_blank" href="https://twitter.com/theburningmonk">theburningmonk</a>,
        <a target="_blank" href="https://aws.amazon.com/developer/community/heroes/yan-cui">AWS Serverless Hero</a>,
        <a target="_blank" href="https://theburningmonk.com/hire-me">independent consultant</a>,
        <a target="_blank" href="https://www.manning.com/books/serverless-architectures-on-aws-second-edition?a_aid=aws-lambda-in-motion&a_bid=9318fc6f">author</a>,
        <a target="_blank" href="https://productionreadyserverless.com">trainer</a>,
        <a target="_blank" href="https://theburningmonk.com">blogger</a> and
        <a target="_blank" href="https://theburningmonk.com/speaking-engagements">speaker</a>
        on all things AWS and serverless.
      </p>

      <ListenOn />
    </div>
  </div>
</template>

<script>
import ListenOn from './ListenOn.vue'

export default {
  components: {
    ListenOn
  }
}
</script>

<style scoped>
a {
  color: #EA5717;
  font-weight: 600;
}
</style>
