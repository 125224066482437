<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f0f0f;
  background-color: #f5f5f5;
}

a {
  color: #EA5717;
}

.button {
  background-color: #EA5717;
  color: white;
  border-color: transparent;
  border-width: 1px;
  border-radius: 0.5rem;
  display: block;
}

.button:hover {
  background-color: #e5703e;
  border-color: black;
  border-width: 1px;
}
</style>
