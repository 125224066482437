<template>
  <div v-if="episode" class="z-50 max-w-3xl h-fit bg-white rounded-lg shadow-lg flex flex-col">
    <div class="w-full flex justify-end">
      <a href="#" @click="$emit('close')"
         class="md:m-2 text-3xl font-extrabold hover:bg-gray-300 py-1 px-3 rounded-full">
        X
      </a>
    </div>

    <!-- main body -->
    <div class="mx-4 md:mx-14 my-2 md:my-5">
      <!-- hero -->
      <div class="flex justify-start items-center">
        <img class="h-36 w-36 mr-5"
          loading="lazy"
          :srcset="`https://res.cloudinary.com/realworldserverless/image/upload/f_auto,c_scale,h_176/website/logo-ep${episode.episode_number}.jpg 320w`"
          sizes="176px"
          :src="episode.artwork_url">

        <h1 class="text-2xl font-bold">{{ episode.title }}</h1>
      </div>

      <div class="my-10 w-full grid grid-cols-4 gap-4 md:grid-cols-8 justify-items-center">
        <a loading="lazy" target="_blank"
           :href="`https://twitter.com/intent/tweet?text=${episode.title}%0A%0A${url}`"
           @click="$emit('shared', 'twitter')">
          <img class="h-16" src="/images/logo-twitter.svg" alt="Twitter" title="Twitter">
        </a>
        <a loading="lazy" target="_blank"
           :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
           @click="$emit('shared', 'facebook')">
          <img class="h-16" src="/images/logo-facebook.svg" alt="Facebook" title="Facebook">
        </a>
        <a loading="lazy" target="_blank"
           :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${episode.title}`"
           @click="$emit('shared', 'linkedin')">
          <img class="h-16" src="/images/logo-linkedin.svg" alt="LinkedIn" title="LinkedIn">
        </a>
        <a loading="lazy" target="_blank"
           :href="`https://pinterest.com/pin/create/button/?url=${url}&media=${episode.artwork_url}&description=${episode.title}`"
           @click="$emit('shared', 'pinterest')">
          <img class="h-16" src="/images/logo-pinterest.svg" alt="Pinterest" title="Pinterest">
        </a>
        <a loading="lazy" target="_blank"
           :href="`whatsapp://send?text=${episode.title}%0A%0A${url}`"
           @click="$emit('shared', 'whatsapp')">
          <img class="h-16" src="/images/logo-whatsapp.svg" alt="WhatsApp" title="WhatsApp">
        </a>
        <a loading="lazy" target="_blank"
           :href="`https://telegram.me/share/url?url=${url}&text=${episode.title}`"
           @click="$emit('shared', 'telegram')">
          <img class="h-16" src="/images/logo-telegram.svg" alt="Telegram" title="Telegram">
        </a>
        <a loading="lazy" target="_blank"
           :href="`http://www.reddit.com/submit?url=${url}&title=${episode.title}`"
           @click="$emit('shared', 'reddit')">
          <img class="h-16" src="/images/logo-reddit.svg" alt="Reddit" title="Reddit">
        </a>
        <a loading="lazy" target="_blank"
           :href="`mailto:?subject=${episode.title}&body=${url}`"
           @click="$emit('shared', 'email')">
          <img class="h-16" src="/images/logo-email.svg" alt="Email" title="Email">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['episode'],
  emits: ['close', 'shared'],
  data () {
    return {
      url: `https://realworldserverless.com/episode/${this.episode.episode_number}`
    }
  }
}
</script>
